/* track */
::-webkit-scrollbar:horizontal {
  background-color: rgba(195, 203, 205, 0.2) !important;
}

/* scrollbar */
::-webkit-scrollbar-thumb {
  border: none !important;
  box-shadow: none !important;
  background-color: #c3cbcd !important;
}

/* last row */
.MuiTableRow-root:last-child td {
  border-bottom: 0;
}

/* first column of table*/
.MuiTableCell-root:first-child {
  padding-left: 32px;
  border-bottom: 1px solid rgba(226, 229, 230, 0.35);
}

/* last column of table*/
.MuiTableCell-root:last-child {
  padding-right: 32px;
  border-bottom: 1px solid rgba(226, 229, 230, 0.35);
}

.MuiList-root {
  display: flex;
  flex-direction: column;
}
/* pagination section*/
.MuiTableRow-footer {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 0px 0px 0px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.MuiPaper-root.MuiPaper-elevation0.MuiPaper-rounded {
  overflow: visible !important;
}

.MuiToolbar-root {
  flex-grow: 1;
}

.MuiFormControl-root {
  width: 100%;
}

.MTableToolbar-spacer-13 {
  flex: 1 1 5% !important;
}

.MuiPagination-ul button {
  color: #2E3190;
  border-color: #2E3190;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-align: center;
}
.MuiPaginationItem-rounded {
  border-radius: 4px !important;
}
.MuiPaginationItem-outlined {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.MuiPaginationItem-root {
  margin: 0 3px !important;
}
.MuiPaginationItem-root.Mui-selected {
  background-color: #ffffff !important;
  border-color: #2E3190 !important;
}

.MuiPaginationItem-root.Mui-disabled {
  background-color: #737b7d;
  opacity: 0.5 !important;
  color: white;
}
.MuiTablePagination-selectLabel {
  display: none !important;
}
.MuiTablePagination-displayedRows {
  display: none !important;
}

.MuiTablePagination-toolbar {
  padding-right: 0px !important;
  width: 135px;
}
/* row selection icon */
.MuiSelect-icon {
  right: calc(7.5%) !important;
}

// search bar
.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}

// grid view
#gridViewHeader + tbody {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 0px;
}

// spinner
.MuiCircularProgress-root {
  position: absolute;
  top: 25%;
}

.MuiCircularProgress-svg {
  color: #2E3190;
}

// checkbox colors
.MuiCheckbox-root {
  padding: 0px !important;
  margin-right: 16px !important;
  color: #c3cbcd !important;
}

.Mui-checked {
  color: #2E3190 !important;
}

//checkbox select all theme
#selectAll {
  position: absolute !important;
  top: -24px;
  left: 150px;
  z-index: 11;
}

#selectAll + thead > tr .MuiTableCell-paddingNone {
  position: absolute;
  top: -35px;
  left: -1px;
  padding-top: 10px;
  border-bottom: none !important;
  background-color: white;
  width: 250px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: none;
  box-sizing: border-box;
}

#selectAll + thead {
  border-bottom: 1px solid rgba(226, 229, 230, 0.35);
}
