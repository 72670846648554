.react-datepicker__input-container input {
  padding-left: 50px;
  height: 47px;
  width: 150px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid rgba(195, 203, 205, 0.35);

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.8);
}
