/* styling for react-datepicker library */
#PageDateSelector .react-datepicker__input-container input {
  padding-left: 55px;
  height: 41.5px;
  width: 164px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid rgba(195, 203, 205, 0.35);
  text-transform: uppercase;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
}
